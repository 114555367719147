var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CommentsDisplay',_vm._b({staticStyle:{"width":"320px","z-index":"100"},on:{"assign":function($event){return _vm.assignTo.apply(void 0, $event)},"archive":function($event){return _vm.archiveComment($event)},"menu-click":function($event){return _vm.onMenuClick.apply(void 0, $event)},"open-page":_vm.openPage,"toggle-edit":_vm.toggleEdit,"set-type":function($event){return _vm.setType.apply(void 0, $event)},"add":_vm.addComment,"resolve":function($event){return _vm.resolveComment.apply(void 0, $event)},"editing-comment":_vm.toggleEdit,"set-commenter-details":_vm.startCommenting,"text":function($event){_vm.commentText = $event}}},'CommentsDisplay',{
    currentPageId: _vm.currentPageId,
    pages: _vm.pages,
    noAuthComments: true,
    comments: _vm.comments,
    addingComment: _vm.addingComment,
    editingComment: _vm.editingComment,
    version: _vm.version,
    commentText: _vm.commentText,
    commenterName: _vm.commenterName,
    commenterEmail: _vm.commenterEmail,
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <CommentsDisplay
    v-bind="{
      currentPageId,
      pages,
      noAuthComments: true,
      comments,
      addingComment,
      editingComment,
      version,
      commentText,
      commenterName,
      commenterEmail,
    }"
    @assign="assignTo(...$event)"
    @archive="archiveComment($event)"
    @menu-click="onMenuClick(...$event)"
    @open-page="openPage"
    @toggle-edit="toggleEdit"
    @set-type="setType(...$event)"
    @add="addComment"
    @resolve="resolveComment(...$event)"
    @editing-comment="toggleEdit"
    @set-commenter-details="startCommenting"
    @text="commentText = $event"
    style="width: 320px; z-index:100;"
  />
</template>
<script>
import { Database } from 'firebase-firestore-lite'
import { mapState } from 'vuex'
import notifySlack from '@/helpers/notifyLite'
import api from '@/helpers/flowApi'
import CommentsDisplay from './CommentsDisplay.vue'
import getSlackUsers from './helpers/slackUsers'
const slackChannel = 'C02GS2AQM09'

const db = new Database({ projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID })

export default {
  components: { CommentsDisplay },
  name: 'Feedback',
  inject: { _updateUserData: { default: () => () => {} } },
  props: {
    id: String,
    form: Object,
    currentPageId: String,
    pages: Array,
    flowGroupId: String,
  },
  data() {
    return {
      comments: [],
      commentText: '',
      commenterName: '',
      commenterEmail: '',
      newCommenterName: '',
      newCommenterEmail: '',
      addingComment: false,
      editingComment: false,
      groupTitle: null,
      replyingToThreadId: null,
    }
  },
  computed: {
    ...mapState(['versionData']),
    formTitle() {
      const formTitle =
        (this.form && ((this.form._meta && this.form._meta.title) || this.form.title)) ||
        `Untitled Flow (${this.id})`
      return this.groupTitle ? `${formTitle} (${this.groupTitle})` : `${formTitle}`
    },
    users() {
      /* Slack Channel: C02GS2AQM09 */
      return getSlackUsers()
    },
    version() {
      return this.versionData && this.versionData.version
    },
  },
  async mounted() {
    const user = this.$store && this.$store.getters && this.$store.getters.user
    this.commenterName =
      (user && user.auth && user.auth.displayName) || localStorage.getItem('SavvyCommenterName')
    this.commenterEmail =
      (user && user.auth && user.auth.email) || localStorage.getItem('SavvyCommenterEmail')
    this.loadComments()

    const { data } = await api(`/public/groups/${this.flowGroupId}`)
    if (data && data.title) this.groupTitle = data.title
  },
  watch: {
    commenterName: {
      handler(v) {
        if (v) localStorage.setItem('SavvyCommenterName', v)
      },
      immediate: true,
    },
    commenterEmail: {
      handler(v) {
        if (v) localStorage.setItem('SavvyCommenterEmail', v)
      },
      immediate: true,
    },
  },
  methods: {
    async loadComments() {
      const comments = await db.ref(`forms/${this.id}/comments`).list()
      this.comments = comments.documents.map(c => ({ ...c, id: c.__meta__.id }))
    },
    updateLocalComment(id, updateData) {
      const index = this.comments.findIndex(c => c.id === id)
      if (index !== -1) {
        this.comments = this.comments.map((c, i) => (i === index ? { ...c, ...updateData } : c))
      }
    },
    startCommenting({ name, email }) {
      this.commenterName = name
      this.commenterEmail = email
    },
    async resolveComment(event, comment, unresolve) {
      this.replyingToThreadId = null

      const isAssignedToSelf =
        comment.assigner && comment.assignee && comment.assigner.uid === comment.assignee.uid

      const updateData = { resolved: unresolve ? false : true }
      await db.ref(`forms/${this.id}/comments/${comment.id}`).update(updateData)
      this.updateLocalComment(comment.id, updateData)

      if (comment.assigner.uid !== comment.assignee.uid) {
        try {
          const assigner = comment.assigner
            ? isAssignedToSelf
              ? `${comment.assigner.name}`
              : `<@${comment.assigner.slackId}>`
            : 'Anonymous'
          const message = `✅ ${this.commenterName} ${
            unresolve ? 'un-' : ''
          }resolved a comment assigned by ${assigner} in \`${this.formTitle}\` (Group: ${
            this.groupTitle
          } \n> ~"${comment.text}"~`
          await notifySlack(message, null, { channel: slackChannel })
        } catch (error) {
          console.error(error)
        }
      }
    },
    async addComment() {
      if (!this.commentText || !this.commentText.trim()) return

      let commentData = this.editingComment
        ? { text: this.commentText, edited: true }
        : {
            location: { page: this.currentPageId, groupId: this.flowGroupId, flowId: this.id },
            author: { name: this.commenterName || null, email: this.commenterEmail || null },
            threadId: this.replyingToThreadId,
            version: this.version,
            resolved: false,
            archived: false,
            text: this.commentText,
            timestamp: new Date().toISOString(),
          }

      this.addingComment = true
      if (this.editingComment) {
        await db.ref(`forms/${this.id}/comments/${this.editingComment}`).update(commentData)
        this.updateLocalComment(this.editingComment, commentData)
      } else {
        await db.ref(`forms/${this.id}/comments`).add(commentData)
        await this.loadComments()
      }
      if (commentData.author) {
        const user = this.users.find(
          u =>
            u.email === commentData.author.email ||
            (this.$store.getters.userId && u.uid === this.$store.getters.userId)
        )
        if (!user) {
          try {
            const message = `${this.commenterName} (${this.commenterEmail}) made a comment in \`${this.formTitle}\` (Group: ${this.groupTitle} - cc <@UEX8PALPR> \n> "${commentData.text}"`
            await notifySlack(message, null, { channel: slackChannel })
          } catch (error) {
            console.error(error)
          }
        }
      }
      this.commentText = ''
      this.addingComment = false
      this.editingComment = false
    },
    toggleEdit(comment) {
      this.replyingToThreadId = null

      if (this.editingComment) {
        this.editingComment = false
        this.commentText = ''
      } else {
        this.editingComment = comment.id
        this.commentText = comment.text
      }
    },
    async archiveComment(id) {
      const updateData = { archived: true }
      await db.ref(`forms/${this.id}/comments/${id}`).update(updateData)
      this.updateLocalComment(id, updateData)

      this.replyingToThreadId = null
    },
    async getFlowTitle() {
      try {
        const flowDoc = await db.ref(`forms/${this.id}`).get()
        const formTitle =
          flowDoc.title ||
          (this.form && ((this.form._meta && this.form._meta.title) || this.form.title))
        return this.groupTitle ? `${formTitle} (${this.groupTitle})` : `${formTitle}`
      } catch (error) {
        console.error(error)
        return this.formTitle
      }
    },
    openPage(pageIndex) {
      this._updateUserData('currentPageId', this.pages[pageIndex].id)
    },
    async assignTo(comment, assignee) {
      const assigner = this.users.find(a => a.uid === this.$store.getters.userId)

      if (!assigner) {
        this.$message.error(`You don't have permission to assign comments`)
        return
      }

      const assigneeHistory = [...(comment.assignee_history || [])].filter(a => a)
      if (comment.assignee) assigneeHistory.push(comment.assignee)
      const prevAssignee = assigneeHistory.slice(-1)[0] || null
      const isSameAssignee = assignee && prevAssignee && assignee.uid === prevAssignee.uid

      await db
        .ref(`forms/${this.id}/comments/${comment.id}`)
        .update({ assignee, assigner, assignee_history: assigneeHistory })

      const verb = assignee ? (assigneeHistory.length ? `re-assigned` : 'assigned') : 'unassigned'
      const preposition = assignee ? (isSameAssignee ? 'back to' : 'to') : 'from'
      const historyContext =
        assignee && prevAssignee && !isSameAssignee ? ` (from ${prevAssignee.name})` : ''

      if (assignee && assigner && assignee.uid === assigner.uid && assigneeHistory.length === 0)
        return

      try {
        const message = `${assigner.name} ${verb} a comment ${preposition} <@${
          (assignee || prevAssignee).slackId
        }>${historyContext} in \`${this.formTitle}\` \n> "${comment.text}"`

        await notifySlack(message, null, { channel: slackChannel })
      } catch (error) {
        console.error(`Error sending Slack notification`, error)
      }
    },
    reply(comment) {
      this.replyingToThreadId = comment && (comment.threadId || comment.id)
    },
    setType(comment, type) {
      console.log('qwer comment, type', comment, type)

      db.ref(`forms/${this.id}/comments/${comment.id}`).update({ type })
    },
    onMenuClick(key, comment) {
      switch (key) {
        case 'reply':
          return this.reply(comment)
        case 'edit':
          return this.toggleEdit(comment)
        case 'resolve':
          return this.resolveComment(event.domEvent, comment)
        case 'unresolve':
          return this.resolveComment(event.domEvent, comment, true)
        case 'archive':
          return this.archiveComment(comment.id)
      }
    },
  },
}
</script>
